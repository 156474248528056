// THIS TYPE WAS AUTOGENERATED
/// <reference path="Domain.ts" />
/// <reference path="SeverityLevel.ts" />
module AI
{
"use strict";
    export class MessageData extends Microsoft.Telemetry.Domain
    {
        public ver: number;
        public message: string;
        public severityLevel: AI.SeverityLevel;
        public properties: any;
        
        constructor()
        {
            super();

            this.ver = 2;
            this.properties = {};
            
            super();
        }
    }
}
