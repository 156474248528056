// THIS TYPE WAS AUTOGENERATED
module AI
{
"use strict";
    export class StackFrame
    {
        public level: number;
        public method: string;
        public assembly: string;
        public fileName: string;
        public line: number;
        
        constructor()
        {
        }
    }
}
