// THIS TYPE WAS AUTOGENERATED
/// <reference path="Base.ts" />
module Microsoft.Telemetry
{
"use strict";
    export class Data<TDomain> extends Microsoft.Telemetry.Base
    {
        public baseType: string;
        public baseData: TDomain;
        
        constructor()
        {
            
            super();
        }
    }
}
