// THIS TYPE WAS AUTOGENERATED
module Microsoft.Telemetry
{
"use strict";
    export class Domain
    {
        
        constructor()
        {
        }
    }
}
