// THIS TYPE WAS AUTOGENERATED
/// <reference path="Domain.ts" />
/// <reference path="SeverityLevel.ts" />
/// <reference path="ExceptionDetails.ts"/>
module AI
{
"use strict";
    export class ExceptionData extends Microsoft.Telemetry.Domain
    {
        public ver: number;
        public handledAt: string;
        public exceptions: ExceptionDetails[];
        public severityLevel: AI.SeverityLevel;
        public problemId: string;
        public crashThreadId: number;
        public properties: any;
        public measurements: any;
        
        constructor()
        {
            super();

            this.ver = 2;
            this.exceptions = [];
            this.properties = {};
            this.measurements = {};
            
            super();
        }
    }
}
