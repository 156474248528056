// THIS TYPE WAS AUTOGENERATED
module AI
{
"use strict";
    export class ExceptionDetails
    {
        public id: number;
        public outerId: number;
        public typeName: string;
        public message: string;
        public hasFullStack: boolean;
        public stack: string;
        public parsedStack: StackFrame[];
        
        constructor()
        {
            this.hasFullStack = true;
            this.parsedStack = [];
        }
    }
}
