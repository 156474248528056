// THIS TYPE WAS AUTOGENERATED
module AI
{
"use strict";
    export enum SeverityLevel
    {
        Verbose = 0,
        Information = 1,
        Warning = 2,
        Error = 3,
        Critical = 4,
    }
}
