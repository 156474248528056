// THIS TYPE WAS AUTOGENERATED
module AI
{
"use strict";
    export enum DataPointType
    {
        Measurement = 0,
        Aggregation = 1,
    }
}
