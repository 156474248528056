// THIS TYPE WAS AUTOGENERATED
module AI
{
"use strict";
    export enum DependencyKind
    {
        SQL = 0,
        Http = 1,
        Other = 2,
    }
}
