// THIS TYPE WAS AUTOGENERATED
module AI
{
"use strict";
    export enum SessionState
    {
        Start = 0,
        End = 1,
    }
}
