// THIS TYPE WAS AUTOGENERATED
module AI
{
"use strict";
    export enum DependencySourceType
    {
        Undefined = 0,
        Aic = 1,
        Apmc = 2,
    }
}
