// THIS TYPE WAS AUTOGENERATED
module Microsoft.Telemetry
{
"use strict";
    export class Base
    {
        public baseType: string;
        
        constructor()
        {
        }
    }
}
