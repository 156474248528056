// THIS TYPE WAS AUTOGENERATED
/// <reference path="DataPointType.ts" />
module AI
{
"use strict";
    export class DataPoint
    {
        public name: string;
        public kind: AI.DataPointType;
        public value: number;
        public count: number;
        public min: number;
        public max: number;
        public stdDev: number;
        
        constructor()
        {
            this.kind = AI.DataPointType.Measurement;
        }
    }
}
